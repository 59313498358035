<template>
  <div>
    <v-card class="px-5 mb-10">
      <v-row>
        <v-col cols="12" md="6">
          <h3 class="primary--text">PETA JABATAN</h3>
        </v-col>
      </v-row>
    </v-card>

    <v-card class="px-3">
      <v-row>
        <v-col cols="12" md="4" style="overflow-x: auto; white-space: nowrap">
          <div class="px-3">
            <h3>DAFTAR UNIT KERJA</h3>
          </div>

          <v-divider class="mt-2"></v-divider>

          <div>
            <v-alert v-model="alertBerhasil" type="success" dense dismissible>
              {{ alertMassage }}
            </v-alert>
            <v-alert v-model="alertGagal" type="error" dense dismissible>
              {{ alertMassage }}
            </v-alert>
          </div>

          <v-treeview
            v-model="selection"
            :items="data"
            :active.sync="selection"
            open-all
            activatable
            return-object
            overflow-x-auto
            item-key="id"
            item-text="nama"
            item-children="bawahan"
            selection-type="independent"
            style="max-height: 75vh;"
          >
          </v-treeview>
        </v-col>

        <v-divider vertical></v-divider>

        <v-col cols="12" md="8" class="pa-6">
          <template v-if="!selection.length">
            Silahkan Pilih Unit Yang Ingin Di Kelola.
          </template>

          <template v-else>
            <div v-for="item in selection" :key="item.id">
              <v-row dense>
                <v-col cols="12" md="10" class="my-auto">
                  <h3>{{ item.nama }}</h3>
                </v-col>

                <v-col cols="12" md="2" class="text-right">
                  <v-tooltip bottom v-if="item.atasan != '0'">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        color="primary"
                        dark
                        icon
                        v-bind="attrs"
                        v-on="on"
                        @click="detail(item)"
                      >
                        <v-icon>mdi-folder</v-icon>
                      </v-btn>
                    </template>
                    <span>Detail</span>
                  </v-tooltip>
                </v-col>
              </v-row>

              <v-divider class="my-4"></v-divider>

              <DUnor :refdata="item" />
            </div>
          </template>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
import Cookie from "@/helper/cookie.js";

import DUnor from "@/components/unor/detail";

import SView from "@/store/unor/view";

export default {
  components: {
    DUnor,
  },

  data: () => ({
    token: Cookie.get("token"),
    ldata: true,

    selection: [],
    data: [],
    ds: {},
  }),

  computed: {
    refresh: {
      get() {
        return SView.state.refresh;
      },
    },

    alertMassage: {
      get() {
        return SView.state.alertMassage;
      },
    },

    success: {
      get() {
        return SView.state.success;
      },
      set(value) {
        SView.commit("alert", value);
      },
    },

    alertBerhasil: {
      get() {
        return SView.state.alertBerhasil;
      },
      set(value) {
        SView.commit("berhasilAlert", value);
      },
    },

    alertGagal: {
      get() {
        return SView.state.alertGagal;
      },
      set(value) {
        SView.commit("gagalAlert", value);
      },
    },
  },

  watch: {
    refresh() {
      this.getData();
      setTimeout(() => {
        this.alertGagal = false;
        this.alertBerhasil = false;
      }, 5000);
    },

    selection(value) {
      const files = JSON.stringify(value);
      var parsed = JSON.parse(files, function (k, v) {
        if (k === "nama") {
          this.name = v;
        } else if (k === "bawahan") {
          this.children = v;
        } else {
          return v;
        }
      });

      this.ds = parsed[0];
    },
  },

  async mounted() {
    this.getData();
  },

  methods: {
    getData() {
      this.ldata = true;
      const url = process.env.VUE_APP_API + "unor/anjab";
      this.axios
        .get(url, {
          headers: {
            Authorization: "Bearer " + this.token,
          },
        })
        .then((res) => {
          this.ldata = false;
          SView.commit("refreshData", false);
          this.data = res.data.mapData;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            // Auth.logout();
          } else if (error.response) {
            console.log(error.response.status);
          } else {
            console.log("Error", error.message);
          }
        });
    },

    detail(item) {
      localStorage.setItem("unor", item.nama);
      this.$router.push("/anjab/mutasi/" + item.id).catch(() => {});
    },
  },
};
</script>